import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: true,

        themes: {
            dark: {
                primary: '#1E6583',
                accent: '#D11345',
                secondary: '#5C9E93',
                info: '#92B49D',
                success: '#C7D19B',
                warning: '#D11345',
                error: '#D11345',
                
                primary2: '#FF00BB',
                bgcolor: '#303030',
            }
        }
    }
});
