import Vue from 'vue'
import VueRouter from 'vue-router'
import BackToHome from '../views/BackToHome.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: BackToHome
  },
  {
    path: '/control',
    name: 'Remote',
    component: () => import('../views/Remote.vue')
  },
  {
    path: '/:client',
    name: 'SetClient',
    component: () => import('../views/SetClient.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
