<template>
    <v-container>
        <v-btn
            href="https://freelancecoder.net"
            >
            Go to Freelance Coder site
        </v-btn>
    </v-container>
</template>

<script>
export default {
}
</script>