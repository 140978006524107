import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    client: '',
  },
  mutations: {
    setClient(state, param) {
      state.client = param
    },
  },
  actions: {
  },
  modules: {
  },
  getters: {
    getClient: state => {
      console.log(state.client)
      return state.client
    },
  }
})
